<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" :fullscreen="$vuetify.display.mobile">
      <v-card>
        <v-card-title> Selecionar vendedor </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- <template v-for="salesman of salesmen">
            <div class="py-4" @click="handleSelect(salesman)" >
              {{ salesman.name }}
            </div>
          </template> -->
          <v-list class="py-0">
            <template v-for="salesman of salesmen">
              <v-list-item class="px-2" @click="handleSelect(salesman)">
                {{ salesman.name }}
                <template v-slot:append>
                  <v-icon color="disabled" icon="mdi:mdi-chevron-right" />
                </template>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn class="text-capitalize" variant="plain" text @click="dialog = false">
            <v-icon class="mr-2" icon="mdi:mdi-arrow-left" />
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <app-text-field :modelValue="selectText" @click="open()" label="Vendedor" readonly :clearable="!readonly"
      density="compact" @click:clear="handleClear()">
      <template v-slot:append-inner>
        <v-icon icon="mdi:mdi-chevron-right" @click="open()" />
      </template>
    </app-text-field>
  </div>
</template>

<script setup>
const emit = defineEmits(["update:modelValue"]);

const prop = defineProps({
  modelValue: {
    default: null,
  },
  readonly: {
    default: false,
  },
});

const dialog = ref(false);

const salesmanStore = useSalesmanStore();

const search = ref(null);

const loading = ref(false);

const salesmen = computed(() => {
  return salesmanStore.salesmen.data;
});

const selectText = computed(() => {
  if (select.value) {
    return select.value.name;
  } else {
    return null;
  }
});

const select = computed({
  get() {
    if (prop.modelValue && prop.modelValue.id) {
      return prop.modelValue;
    } else {
      return null;
    }
  },

  set(value) {
    emit("update:modelValue", value);
  },
});

const getSalesman = async (value) => {
  loading.value = true;

  await salesmanStore.index();

  loading.value = false;
};

const open = () => {

  if (prop.readonly) return;

  getSalesman();

  dialog.value = true;
};

const handleSelect = (salesman) => {
  select.value = salesman;

  dialog.value = false;
};

const handleClear = () => {
  var filter = {};

  for (var key in select.value) {

    if (select.value[key] != null && typeof select.value[key] == "object") {
      filter[key] = null;
      filter[key + "_id"] = null;
    }
  }

  select.value = filter;

  dialog.value = false;
};
</script>
